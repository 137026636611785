import React, { useState } from "react";
import Stars from "../../components/global/Logout/Stars";



function Faq() {

    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p className="text">
                {isReadMore ? text.slice(0, 100) : text}
                <span onClick={toggleReadMore} className="read-or-hide text-gray-400">
                    {isReadMore ? "...read more" : " show less"}
                </span>
            </p>
        );
    };
    const moverReview = [
        {
            UserName: "Jeanne T. San Dimas, CA",
            Date: "10/18/2023",
            Rate: 5.0,
            Description: "Professional & knowledgeable. Eli was very easy to work with and they did a wonderful job from start to finish. I definitely recommend them. It was a pleasure doing business with kind, honest reliable gentlemen.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "David B. Riverside, CA",
            Date: "10/6/2023",
            Rate: 5.0,
            Description: "I was always kept updated on progress.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Benjamin F. Riverside, CA",
            Date: "10/2/2023",
            Rate: 5.0,
            Description: "Moreno Brothers concrete did an awesome job extending my driveway. Very reasonable price.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "David B. Riverside, CA",
            Date: "9/29/2023",
            Rate: 5.0,
            Description: "Moreno Bros explained every step. I knew everything to expect. Very courteous.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Kathy V. Null, CA",
            Date: "7/31/2023",
            Rate: 5.0,
            Description: "They got the job done when they said it would be done. Very satisfied with the work",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Frances R. San Dimas, CA",
            Date: "7/15/2023",
            Rate: 5.0,
            Description: "Work was done in a very timely and professional manner. Workers very courteous and hard working.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Lane C. Monterey Park, CA",
            Date: "2/23/2023",
            Rate: 5.0,
            Description: "They did a great job tearing out existing concrete and putting in a new patio and walkways. They were detail oriented and planned everything out really well but also worked quickly and efficiently. It just rained and the water flowed out perfectly as designed. Never got anywhere near the house. Great communication and customer service.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "John L. Glendora, CA",
            Date: "1/26/2023",
            Rate: 5.0,
            Description: "This is my 2nd project with the Moreno Brothers. They did a great job again. Highly recommended.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Rod K. Arcadia, CA",
            Date: "8/28/2022",
            Rate: 5.0,
            Description: "Fast service, quality work, knowledgeable",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Reem S. Glendora, CA",
            Date: "7/18/2022",
            Rate: 5.0,
            Description: "Moreno brother did an amazing job, they tore down the old cracked walkway entrance and put a new one with stairs and curbs. The new entrance looks great, they gave me a reasonable estimate and came on time and finished the job in one day. Very professional, hard working and honest people. I strongly recommend them and will use them again.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },


        {
            UserName: "John L. Glendora, CA",
            Date: "7/14/2022",
            Rate: 5.0,
            Description: "Great customer service. Hard working crew. Finished the job in 2 days. We were very happy with everything. I would definitely recommend them for concrete work.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Rod K. Rowland Heights, CA",
            Date: "7/14/2022",
            Rate: 5.0,
            Description: "This is the second time i hired them for a major project. The knowledge of their work is unsurpassed. The crew is very efficient and gets the job done with no delays. I would certainly hire them again.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "John W. La Verne, CA",
            Date: "6/12/2022",
            Rate: 5.0,
            Description: "I met with others from the site and picked them off of the work I saw they did. They are very proud of what the do and I have nothing but great things to say. I will be hiring them again",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Linda C. West Covina, CA",
            Date: "3/8/2022",
            Rate: 5.0,
            Description: "The Moreno Brothers were very knowledgeable and experienced, great pricing, I have referred them already to potential customers, and will continue to refer everyone to them. They were very professional, and delivered my vision and more.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Kurt S. Jurupa Valley, CA",
            Date: "2/16/2022",
            Rate: 5.0,
            Description: "They needed very little instruction they did a fantastic job at what they did their work turned out very nice and I plan to use them for more projects now that I've seen their work",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Terry H. Chino Hills, CA",
            Date: "10/26/2021",
            Rate: 5.0,
            Description: "They did a great job, I'm very pleased with their knowledge of what needed to be done. Very professional. Would use them again",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Stephanie M. Montclair, CA",
            Date: "10/25/2021",
            Rate: 5.0,
            Description: "Very responsive and helpful",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Jerry B. Ontario, CA",
            Date: "10/6/2021",
            Rate: 5.0,
            Description: "Very professional communications, they let me know exactly the steps they would take and time to complete. They left my property clean and maybe better than when they arrived. The quality of the concrete work is flawless. I am a very happy customer and highly recommend if you need concrete work, don't use any one else.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Melanie K. Ontario, CA",
            Date: "7/26/2021",
            Rate: 5.0,
            Description: "Great customer service and great work. They were on time and very personable.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Salvador H. Ontario, CA",
            Date: "7/26/2021",
            Rate: 4.5,
            Description: "Great expertise, on time, on schedule and budget, very friendly and open to suggestions, my back yard was done fast with quality and the way I wanted it, couldn't be happier",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Janeth R. Wasco, CA",
            Date: "7/24/2021",
            Rate: 5.0,
            Description: "Amazing job and very professional. Everything turned out better than I could have imagined.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Rod K. Rowland Heights, CA",
            Date: "6/4/2021",
            Rate: 5.0,
            Description: "Always on time. Fair price for the work and excellent results.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Juan S. Covina, CA",
            Date: "5/30/2021",
            Rate: 5.0,
            Description: "Good, honest and reliable. Good price for the project performed. Will highly recommend to family and friends.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Paul F. Covina, CA",
            Date: "5/28/2021",
            Rate: 5.0,
            Description: "I worked with Eleazar Moreno The job came out better than what I was envisioning being he has probably done hundreds of these so when he made suggestions here there about the lawn sidewalk and views I took into consideration what he said thought about it overnight and he's right it is my entrance so he made it standout much better and the entrance and walkway look so much better. Also redid part of my driveway looks nice thanks Eleazar I would recommend Moreno Brothers concrete professional, fast, friendly service. Hard working.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Ruben G. Norwalk, CA",
            Date: "4/21/2021",
            Rate: 5.0,
            Description: "Timely work",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Rosa G. Diamond Bar, CA",
            Date: "4/17/2021",
            Rate: 5.0,
            Description: "I highly recommend the Moreno Brothers. They are professional, hardworking and deliver good work. I am so happy with my new concrete patio.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Charles O. Pasadena, CA",
            Date: "4/14/2021",
            Rate: 5.0,
            Description: "Fast response, good quality work at a reasonable price. Family business with a friendly crew that is responsive.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Pritam B. San Dimas, CA",
            Date: "3/27/2021",
            Rate: 5.0,
            Description: "It was very easy to work with Moreno brother and they were giving good suggestions. I would surely recommend them.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Ryan S. Fullerton, CA",
            Date: "3/14/2021",
            Rate: 5.0,
            Description: "Professional and courteous. Efficient and great workmanship.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Patricia M. Diamond Bar, CA",
            Date: "2/27/2021",
            Rate: 5.0,
            Description: "Mr. Moreno and his crew paved a walkway in my front yard that came out excellent. He was available for questions from me before, during, and after the project. His work is professional grade and I recommend his company and would hire them again.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        }
        ,
        {
            UserName: "Jeb B. Covina, CA",
            Date: "2/23/2021",
            Rate: 5.0,
            Description: "We loved working with all the Moreno brothers, their sons, and nephews. Our contractors had ghosted us and my fiance found the Moreno brothers through Home Advisor. Eleazar reached out to my fiance the same day and then his brother came out to our house the next day to give us an estimate of the costs needed to finish our front porch and our backyard patio. They exceeded our expectations tremendously! I loved that they are prompt (on time even early and they stay late (until the job is done. They cleaned up everything (all the trash, excess concrete, swept the dirt, washed down our driveway I could go with how great they are. I highly recommend them and we will be working with the Moreno brothers again soon to now finish the other half of our back yard. and side of our house. THANK YOU AGAIN FOR ALL THE AMAZING AND HARD WORK! Please note this is written by Catherine the fiance of Jeb :",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Tony P. Covina, CA",
            Date: "2/12/2021",
            Rate: 4.5,
            Description: "Mr Moreno came out the same day I called for an estimate. He and his crew were all very polite gentlemen and they did great work. I highly recommend Moreno Brothers Concrete.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "David M. Monrovia, CA",
            Date: "2/6/2021",
            Rate: 5.0,
            Description: "Eleasar Moreno is probably the best contractor I have worked with. He and his team are very professional, polite, do what they say they will do, are on time, and work very hard, and to a high standard. Definitely consider using them for any concrete work you have.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Liang T. San Marino, CA",
            Date: "1/12/2021",
            Rate: 5.0,
            Description: "They are good at time management, hard working and quality workers. They did a great job on my concrete patio and concrete retaining wall. Would hire them again!",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Maria D. Diamond Bar, CA",
            Date: "1/3/2021",
            Rate: 5.0,
            Description: "Very professional; good communication via text, phone and in person; work done on time if not sooner; cleaned everything and replaced the hose that bursted out in a process. It was my best experience ever. Thank you Moreno Brother's.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Edward B. Diamond Bar, CA",
            Date: "11/14/2020",
            Rate: 5.0,
            Description: "Easy to work with, great communication, great price and a very skilled team. Our patio and walkway came out perfect and was completed sooner then expected.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "David V. Glendora, CA",
            Date: "10/24/2020",
            Rate: 5.0,
            Description: "This team was great. They did the job quickly and the job came out even better than we imagined. I would recommend them to anyone I know who needs concrete work done.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Terry S. Fullerton, CA",
            Date: "10/18/2020",
            Rate: 5.0,
            Description: "Great customer service!! The finished project looked perfect.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Mike P. Glendora, CA",
            Date: "10/11/2020",
            Rate: 5.0,
            Description: "They were always here when they said they would be. The work was done very orderly and explained everything they were doing. Completed project when promised and cleaned everything around area, in which they worked.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Jim A. Rancho Cucamonga, CA",
            Date: "7/23/2020",
            Rate: 5.0,
            Description: "Prompt, efficient, excellent work done by skilled professionals.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Curtis P. Ontario, CA",
            Date: "7/14/2020",
            Rate: 5.0,
            Description: "I would highly recommend Moreno Brother's Concrete to everyone. I will use them in the future.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Fred T. Fullerton, CA",
            Date: "7/7/2020",
            Rate: 5.0,
            Description: "This concrete company knows what its doing when poring concrete All the men work together really well They we re very friendly answered all my questions and very friendly. They came with all needed equipment and then some, it made the job go smooth and fast. This kind of work is messy but they did the best clean up I ve seen in a long time Good work good job.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "William C. Upland, CA",
            Date: "6/24/2020",
            Rate: 5.0,
            Description: " ",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Tom U. San Dimas, CA",
            Date: "6/17/2020",
            Rate: 5.0,
            Description: "Moreno Brothers did an excellent job on our project. We will use them again.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Lavonne G. Upland, CA",
            Date: "6/6/2020",
            Rate: 5.0,
            Description: "Professionalism and cleanliness was excellent.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Nikki H. La Verne, CA",
            Date: "5/30/2020",
            Rate: 5.0,
            Description: "They did a fabulous job. On time. Cleaned up after themselves. I would definitely use them again!",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Tom B. Chino, CA",
            Date: "5/27/2020",
            Rate: 5.0,
            Description: "Moreno Brothers did an amazing job on our 700 square-foot patio! From start to finish they were professional and knowledgeable walking us through the whole process. Their experience really shows with the preparation of our patio from elevation to leveling out the existing dirt before pouring cement. I m especially impressed on how well water flows perfectly to the drains. I highly recommend them and our family is very satisfied with our new patio. Big thanks to the Moreno Brothers!!!",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Yolanda E. Covina, CA",
            Date: "5/24/2020",
            Rate: 5.0,
            Description: "They were on time. Job was done when that said it would be. Communication was great.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Doug K. Pasadena, CA",
            Date: "5/15/2020",
            Rate: 5.0,
            Description: " ",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Rose S. West Covina, CA",
            Date: "5/11/2020",
            Rate: 5.0,
            Description: "I worked with Eli from beginning to end, he worked with me every step of the way. He was professional, confident in his work offerings and in meeting his completion date. He immediately sent pics of previous jobs, colors samples and returned my calls in a timely manner. His crew was very efficient, experienced, and made sure to clean up well before they left. A job well done!",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Kristen W. San Dimas, CA",
            Date: "5/10/2020",
            Rate: 5.0,
            Description: "Fantastic service, for a fair price. I will definitely recommend them to everyone",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Bobby K.",
            Date: "4/8/2020",
            Rate: 5.0,
            Description: "No comments.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        },
        {
            UserName: "Jose R.",
            Date: "4/8/2020",
            Rate: 5.0,
            Description: "They are experienced and hard working.",
            red: "HomeAdvisor",
            enlace: "https://www.homeadvisor.com/rated.ELEAZARMORENOSAUCEDO.67118855.html",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/homeadvisor.png?alt=media&token=a0c6979c-44bd-4023-92e1-6b9db400dd49&_gl=1*1m4no0q*_ga*MTE3MTYyMjExMy4xNjg2MTY3OTI1*_ga_CW55HF8NVT*MTY5Nzc1NDE5Ny4yMTAuMS4xNjk3NzU0MjU3LjYwLjAuMA.."
        }
    ];


    return (
        <section className="w-4/5 h-auto my-10 mx-auto gap-5 grid md:grid-cols-3 grid-cols-1">
            {
                moverReview.map((item, index) => {
                    return (
                        <article key={index} className="h-auto p-2 flex flex-col rounded-3xl bg-gray-100 w-auto">
                            <div className="flex flex-col">
                                <div className="p-2 flex space-x-2">
                                    <div className="flex justify-center">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/user.jfif?alt=media&token=2e7c59b5-a71c-4739-8260-bb88b37b08fa"
                                            alt="perfil-foto"
                                            className="w-[50px] h-auto rounded-full object-cover" />
                                    </div>
                                    <div>
                                        <span className="text-[20px] font-semibold"> {item.UserName} </span>
                                        <Stars score={item.Rate} />
                                    </div>
                                    <div className="flex items-end text-[13px] text-gray-400">
                                        <span>{item.Date}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="p-5 ">
                                <ReadMore >{item.Description}</ReadMore>
                            </div>
                            <div className="w-full flex items-center space-x-3 ml-3">
                                <div className="w-[25px] h-[25px] flex items-center">
                                    <img src={item.ima} alt="red" className="w-full mx-auto" />
                                </div>
                                <div className="flex flex-col items-center justify-start">
                                    <p>Posted On</p>
                                    <a className="text-blue-500 w-full" href={item.enlace}>{item.red}</a>
                                </div>
                            </div>
                        </article>
                    );
                })
            }
        </section>
    );
}

export default Faq;